@use "@angular/material" as mat;
@include mat.core();

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "tailwindcss/screens";
:root{
  --theme-blue-rgb: 69, 81, 229;
  --black-clr: #000000;
}
@font-face {
  font-family: "ProximaNova-Thin";
  src: url(/assets/fonts/PN-Thin.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Thin-Italic";
  src: url(/assets/fonts/PN-Thin-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url(/assets/fonts/PN-Light.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Light-Italic";
  src: url(/assets/fonts/PN-Light-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url(/assets/fonts/PN-Regular.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Regular-Italic";
  src: url(/assets/fonts/PN-Regular-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url(/assets/fonts/PN-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Semibold-Italic";
  src: url(/assets/fonts/PN-Semibold-Italic.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url(/assets/fonts/PN-Bold.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Bold-Italic";
  src: url(/assets/fonts/PN-Bold-Italic.otf) format("opentype");
}

@layer base {
  html {
    font-family: ProximaNova-Regular, system-ui, sans-serif;
  }
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$md-grata: (
  50: #e8f5ff,
  100: #c5e5fe,
  200: #9ed4fe,
  300: #77c3fd,
  400: #5ab6fc,
  500: #3da9fc,
  600: #37a2fc,
  700: #2f98fb,
  800: #278ffb,
  900: #1a7efa,
  A100: #ffffff,
  A200: #f9fcff,
  A400: #c6deff,
  A700: #adcfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$web-primary: mat.define-palette($md-grata);
$web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$web-theme: mat.define-light-theme($web-primary, $web-accent, $web-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);

.mat-drawer-container {
  background-color: #FFF;
}

.mat-toolbar {
  background-color: #FFF;
}

/* You can add global styles to this file, and also import other style files */
.fs-12 {
  font-size: .75rem;
}
.fs-14 {
  font-size: .875rem;
}
.fs-18 {
  font-size: 1.125rem;
}
.fs-20 {
  font-size: 1.25rem;
}
.fs-22 {
  font-size: 1.375rem;
}
.fs-24 {
  font-size: 1.5rem;
}

::ng-deep .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.select-box{max-width: 400px;
  mat-form-field{border: 1px solid #D1D5DB; border-radius: 6px; width: 380px;
      .mat-mdc-text-field-wrapper{background: transparent;
          .mat-mdc-form-field-focus-overlay{background: transparent;}
      }
      .mdc-text-field{padding: 0 !important;}
      .mat-mdc-form-field-infix{min-height: 33px; width: auto; min-width:380px; max-width: 380px; padding: 0 !important;}
      .mat-mdc-form-field-subscript-wrapper{display: none;}
      .mdc-line-ripple{display: none;}
      .mat-mdc-select-value{color: #353E48; padding-right: 10px; padding-left: 5px;}
      .mat-mdc-select-min-line{font-size: 0.875rem;  font-weight: 600; line-height: 20px;}
  }
}
.custom-date-picker{
  mat-form-field{
    .mat-mdc-text-field-wrapper{height: 44px !important;}
    .mat-mdc-icon-button.mat-mdc-button-base{
      width: 48px;
      height: 48px;
      margin: -4px !important;
      padding-top: 2px !important}
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
      padding-top: 14px !important;
      padding-bottom: 9px !important;

    }
  }
}
.bottom-page {

  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper { margin-bottom: 0px; margin-left: 0px; margin-right: 0px; margin-top: 9px; background-color: #fff; border-radius: 6px;}

  .mat-form-field-appearance-outline .mat-mdc-form-field-infix { padding-top: 6px; padding-bottom: 6px; min-height: auto;}

  .mat-mdc-text-field-wrapper {padding-bottom: 0px;}

  .mat-mdc-form-field-infix { border-top: 0.14375em solid transparent; min-height: auto;}

  .mat-form-field-appearance-outline .mat-mdc-select-arrow-wrapper {display: flex;}

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {color: rgba(0, 0, 0, .12);}

  .mat-form-field-appearance-outline .mat-mdc-form-field-infix { width: 132px; min-height: auto; padding-left: 10px; padding-right: 10px;}

  .mat-mdc-select-arrow {

    //  color: var(--theme-light-clr) !important;

    color: #1a7efa !important;

  }

  .mat-mdc-select-arrow-wrapper {height: 0 !important;}

  .mat-mdc-select-min-line { font-family: 'Montserrat'; font-size: 12px; font-weight: 500;

    //  color: var(--theme-light-clr);

    color: #1a7efa;

  }

}
.pagination { margin: 10px 15px; padding: 0px; list-style: none; float: right;
  li {
    .back { background: #fff; outline: none; border: 0px; display: inline-block; height: 40px; padding: 9px 15px; border: 1px solid  #1a7efa; border-top-left-radius: 7px; border-bottom-left-radius: 7px; cursor: pointer; color: #1a7efa;}
    .next { background: #fff; outline: none; border: 0px; display: inline-block; height: 40px; padding: 9px 15px; border: 1px solid  #1a7efa; border-top-right-radius: 7px; border-bottom-right-radius: 7px; color: #1a7efa;}
  }
  .pagination-number { background: #fff; outline: none; border: 0px; display: inline-block; word-break: normal; white-space: nowrap; padding: 0px;
    button { color:  #1a7efa; background: transparent; font-size: 15px;  border: 0px; outline: none; height: 40px; width: 40px; font-weight: 700; padding: 0px; border: 1px solid  #1a7efa;
      &:hover {color: #1a7efa;}
    }
    .active {color: #1a7efa;}
    button:last-child {border-right: 0px;}
  }
}
  .pagination-left {

    .mat-mdc-select-arrow {border-image-source: none !important;}

  }
